import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.jsx";
import Divider from '../components/atoms/Divider';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Main note operations`}</h2>
    <p>{` `}<img parentName="p" {...{
        "src": "https://i.imgur.com/y4ZlmhG.png",
        "alt": null
      }}></img></p>
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to add a template to favorites?`}</h2>
    <p>{` `}<img parentName="p" {...{
        "src": "https://i.imgur.com/AgGTu7H.png",
        "alt": null
      }}></img></p>
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to create custom template with PDF?`}</h2>
    <p>{` `}<img parentName="p" {...{
        "src": "https://imgur.com/ztWf6jy.gif",
        "alt": null
      }}></img></p>
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to delete your custom template?`}</h2>
    <p>{` `}<img parentName="p" {...{
        "src": "https://i.imgur.com/1YickZt.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      